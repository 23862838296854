import * as React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Profile from "../components/organisms/profile"
import TagsList from "../components/organisms/tags-list"
import HatenaArticles from "../components/organisms/hatena-articles"
import { Box, Grid, GridItem, Heading, Text } from "@chakra-ui/react"
import CategoriesArticleCards from "../components/organisms/categories-article-cards"
import { categories } from "../params/categories"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMdx.nodes
  const sideBarContents = [
    <Profile headerDisplay={true} />,
    <HatenaArticles />,
    <TagsList />,
  ]

  return (
    <Layout
      location={location}
      title={siteTitle}
      sideBarContents={sideBarContents}
      isTwoColumn={false}
      bgColor="white"
    >
      <Seo title="Home" />

      {categories.map((block) => {
        return (
          <Box mb="6em">
            <Heading as="h2" textAlign="center" my="0.8em">
              <Box fontSize="2rem" color="red.700">
                {block.blockName}
              </Box>
            </Heading>
            <Grid
              templateColumns={{
                base: "repeat(1,1fr)",
                lg: "repeat(2, 1fr)",
              }}
              gap={12}
              justify="center"
            >
              {block.category.map((category) => (
                <GridItem>
                  <CategoriesArticleCards
                    categoryId={category.id}
                    categoryName={category.name}
                    showCategoryName={true}
                    as="h3"
                  />
                  <Link to={`/category/${category.id}`}>
                    <Text
                      textAlign="right"
                      fontSize="0.85rem"
                      mx="1em"
                      _hover={{
                        textDecoration: "underline",
                        color: "#E53E3E",
                      }}
                    >
                      カテゴリ記事一覧 →
                    </Text>
                  </Link>
                </GridItem>
              ))}
            </Grid>
          </Box>
        )
      })}
    </Layout>
  )
}

export default BlogIndex

export const query = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      filter: { fileAbsolutePath: { regex: "/myblog/" } }
      sort: { fields: frontmatter___created, order: DESC }
    ) {
      nodes {
        frontmatter {
          slug
          title
          modified_date(formatString: "YYYY/M/DD")
          created(formatString: "YYYY/M/DD")
          tags
          category
          description
          thumbnail_image {
            childImageSharp {
              gatsbyImageData(width: 300, layout: CONSTRAINED)
            }
          }
        }
        parent {
          ... on File {
            sourceInstanceName
          }
        }
      }
    }
  }
`
