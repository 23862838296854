import * as React from "react"
import { Box, Heading, List, ListItem } from "@chakra-ui/react"
import { useStaticQuery, graphql, Link } from "gatsby"

import ArticleCard from "../molecules/article-card"

const CategoriesArticleCards = ({
  categoryId,
  categoryName,
  as = "h2",
  showCategoryName = true,
}) => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
      allMdx(
        filter: { fileAbsolutePath: { regex: "/myblog/" } }
        sort: { fields: frontmatter___created, order: DESC }
      ) {
        nodes {
          frontmatter {
            slug
            title
            modified_date(formatString: "YYYY/M/DD")
            created(formatString: "YYYY/M/DD")
            tags
            category
            description
            thumbnail_image {
              childImageSharp {
                gatsbyImageData(width: 150, layout: FIXED)
              }
            }
          }
          parent {
            ... on File {
              sourceInstanceName
            }
          }
        }
      }
    }
  `)

  const posts = data.allMdx.nodes
  const filterdPosts = posts
    .filter((post) => post.frontmatter.category === categoryId)
    .splice(0, 3)

  return (
    <Box
      justify="center"
    >
      {showCategoryName ? (
        <Link to={`/category/${categoryId}`}>
          <Heading
            as={as}
            fontSize="1.3rem"
            textAlign="center"
            py="0.5em"
            mx="0%"
            my="0.5em"
            borderBottom="1px solid"
            borderColor="gray.500"
            _hover={{ fontSize:"1.35rem", color: "red.500" , borderColor:"red.500"}}
            // _groupHover={{ color: "red.500" , borderColor:"red.500" }}
          >
            {`${categoryName}`}
          </Heading>
        </Link>
      ) : (
        ""
      )}
      <List style={{ listStyle: `none` }}>
        {filterdPosts.map((post) => {
          const title = post.frontmatter.title || "タイトルなし"
          const slug = post.frontmatter.slug
          const created = post.frontmatter.created
          const lastModified = post.frontmatter.modified_date[0] || ""
          const description = post.frontmatter.description || post.excerpt
          const tags = [post.frontmatter.tags[0]] || [""]
          const ogpImage =
            post.frontmatter.thumbnail_image &&
            post.frontmatter.thumbnail_image.childImageSharp.gatsbyImageData
          return (
            <ListItem key={slug}>
              <ArticleCard
                title={title}
                slug={slug}
                created={created}
                lastModified={lastModified}
                description={description}
                tags={["tags"]}
                ogpImage={ogpImage}
              />
            </ListItem>
          )
        })}
      </List>
    </Box>
  )
}

export default CategoriesArticleCards
